<template>
    <div class="page">
        <div class="title">
            <div class="name">维保工单</div>
            <div class="search">
                <Select v-model="formItem.villageId" placeholder="全部小区" clearable style="width:120px">
                    <Option v-for="(item, index) in villageFormItemSelectList" :key="index" :value="item.v_village_id">
                        {{ item.v_village_name }}</Option>
                </Select>
                <Select v-model="formItem.vElevatorCode" placeholder="全部电梯" clearable style="width:120px">
                    <Option v-for="(item, index) in elevatorSelectList" :key="index" :value="item.v_elevator_code">
                        {{ item.v_elevator_code }}</Option>
                </Select>
                <Select v-model="formItem.iFaultType" placeholder="维保类型" clearable style="width:140px">
                    <Option v-for="(item, index) in iFaultTypeList" :key="index" :value="item.name">
                        {{ item.label }}</Option>
                </Select>
                <Select v-model="formItem.overdue" placeholder="逾期类型" clearable style="width:140px">
                    <Option v-for="(item, index) in overdueList" :key="index" :value="item.name">
                        {{ item.label }}</Option>
                </Select>
                <!-- <Select
          v-model="formItem.iStatus"
          placeholder="工单状态"
          clearable
          style="width:140px"
        >
          <Option
            v-for="(item, index) in iStatusList"
            :key="index"
            :value="item.name"
          >
            {{ item.label }}</Option
          >
        </Select> -->
                <DatePicker :value="formItem.dtReportTime" format="yyyy-MM-dd" type="date" style="width:120px" placeholder="开始时间" @on-change="formItem.dtReportTime = $event" />
                <DatePicker :value="formItem.dtEndTime" @on-change="formItem.dtEndTime = $event" format="yyyy-MM-dd" type="date" style="width:120px" placeholder="结束时间" />
                <Button type="primary" @click="searchList()">查询</Button>
            </div>
        </div>
        <div class="content">
            <tblcomponents ref="tblcomponents" :formItem="formItem" :tabelConfigVal="tabelConfig" :tabelDataVal="tabelData" :tabelLoadingVal="tabelLoading" :totalRecordCntVal="totalRecordCnt" @search="searchList" data-index="vDeptId" />
        </div>
        <Modal footer-hide :mask="true" :mask-closable="false" :scrollable="true" class-name="vertical-center-modal" title="详情" v-model="modaDetail" width="950">
            <div style="background:#fff">
                <Row :gutter="10">
                    <Col :lg="14" :md="14" :sm="14" :xs="14" class="flexVideo">
                    <Tabs size="small">
                        <TabPane label="视频" class="TimeDiv">
                            <video :src="videoUrl" controls ref="video" v-if="videoUrl != '' && videoUrl != null">
                                您的浏览器不支持video元素。
                            </video>
                            <!-- <svg-icon :icon-class="'notViodeo'" class="svg-icon_not_video" v-if="!videoUrl" /> -->
                            <br />
                            <font style="margin-left:205px" v-if="!videoUrl">暂无视频</font>
                        </TabPane>
                        <TabPane label="图片">
                            <Scroll v-if="faultImageList.length !== 0">
                                <div :key="index" dis-hover style="margin: 32px 0" v-for="(item, index) in faultImageList">
                                    <img :src="item" style="max-width:100%" />
                                </div>
                            </Scroll>
                            <!-- <svg-icon :icon-class="'notImgList'" class="svg-icon_not_video" v-if="faultImageList.length === 0" /> -->
                            <br />
                            <font style="margin-left:210px" v-if="faultImageList.length === 0">暂无图片</font>
                        </TabPane>
                    </Tabs>
                    </Col>
                    <Col :lg="10" :md="10" :sm="10" :xs="10">
                    <CellGroup>
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.v_fault_id + '' : '--'
                " title="不文明行为ID" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.v_elevator_name + '' : '--'
                " title="电梯编号" />
                        <Cell :extra="
                  faultInfoDetail.v_address
                    ? faultInfoDetail.v_address + ''
                    : '--'
                " title="电梯地址" />
                        <Cell :extra="
                  faultInfoDetail.v_elevator_type
                    ? faultInfoDetail.v_elevator_type + ''
                    : '--'
                " title="电梯类型" />
                        <Cell :extra="
                  faultInfoDetail.dt_report_time
                    ? faultInfoDetail.dt_report_time + ''
                    : '--'
                " title="上报时间" />
                        <Cell :extra="
                  faultInfoDetail.dt_end_time
                    ? faultInfoDetail.dt_end_time + ''
                    : '--'
                " title="结束时间" />
                        <Cell :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_fault_type +
                      '-' +
                      faultInfoDetail.v_fault_name +
                      ''
                    : '--'
                " title="类型" />
                        <Cell :extra="
                  faultInfoDetail.v_fault_second_type
                    ? faultInfoDetail.v_fault_second_type +
                      '-' +
                      faultInfoDetail.v_fault_second_name +
                      ''
                    : '--'
                " title="子类型" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.i_fault_num + '' : '--'
                " title="上报次数" />
                        <Cell :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_mode_status_name + ''
                    : '--'
                " title="所处服务模式" />
                    </CellGroup>
                    </Col>
                </Row>
            </div>
        </Modal>
    </div>
</template>
<script>
import {
    queryFaultList,
    generateMaintenancePdf
} from '@/api/maintenance/index.js'


import {
    getElevatorsStatus,
    reqElevatorProjectSelectList,
    searchVillageSelectList
} from '@/api/home/index.js'
export default {
    data() {
        return {
            modaDetail: false,
            iFaultTypeList: [{
                    name: 'BYLX01',
                    label: '半月保'
                },
                {
                    name: 'BYLX02',
                    label: '季度保'
                },
                {
                    name: 'BYLX03',
                    label: '半年保'
                },
                {
                    name: 'BYLX04',
                    label: '年度保'
                }
            ],
            overdueList: [{
                    name: 0,
                    label: '未逾期'
                },
                {
                    name: 1,
                    label: '逾期'
                }
            ],
            iStatusList: [{
                    name: 10,
                    label: '新建'
                },
                {
                    name: 25,
                    label: '已签到'
                },
                {
                    name: 30,
                    label: '已完成'
                },
                {
                    name: 40,
                    label: '已确认'
                }
            ],
            faultImageList: '',
            model1: '',
            faultInfoDetail: {},
            videoUrl: '',
            searchLoading: false,
            open: false,
            tabelConfig: [{
                    title: '电梯名称',
                    key: 'v_elevator_name',
                    width: "180px"
                },

                {
                    title: '小区',
                    key: 'villageName'
                },
                {
                    title: '安装地址',
                    key: 'v_address'
                },
                {
                    title: '开始时间',
                    key: 'should_complete_date'
                },
                {
                    title: '结束时间',
                    key: 'complete_time'
                },
                {
                    title: '状态',
                    key: 'i_status',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'font',
                                params.row.i_status == 10 ?
                                '新建' :
                                params.row.i_status == 25 ?
                                '已签到' :
                                params.row.i_status == 30 ?
                                '已完成' :
                                '已确认'
                            )
                        ])
                    }
                },
                {
                    title: '保养类型',
                    key: 'order_type_number',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'font',
                                params.row.order_type_number == 'BYLX01' ?
                                '半月保' :
                                params.row.order_type_number == 'BYLX02' ?
                                '季度保' :
                                params.row.order_type_number == 'BYLX03' ?
                                '半年保' :
                                '年度保'
                            )
                        ])
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 240,
                    align: 'center',
                    render: (h, params) => {
                        const btns = []
                        btns.push(
                            h(
                                'Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            window.open(params.row.fileUrl, '_blank')
                                            // generateMaintenancePdf({
                                            //     workOrderNumber: params.row.workOrderNumber
                                            // }).then(res => {
                                            //     console.log(res)
                                            //     if (res.info.fileUrl) {
                                            //         window.open(res.info.fileUrl, '_blank')
                                            //     }
                                            // })
                                        }
                                    }
                                },
                                '查看pdf'
                            )
                        )

                        return h('div', btns)
                    }
                },
                {
                    title: '维保人员',
                    key: 'deal_employee_name'
                }
            ],
            elevatorFormItemProjectSelectList: [],
            villageFormItemSelectList: [],
            tabelData: [],
            tabelLoading: false,
            totalRecordCnt: 0,
            formItem: {
                villageId: '',
                buildingId: '',
                vElevatorCode: '',
                dtEndTime: this.formatDate(0) + '',
                dtReportTime: this.formatDate(-30) + '',
                iFaultType: '',
                overdue: '',
                iStatus: '',
                pageIndex: 1,
                pageSize: 10,
                vProjectId: ''
            },
            elevatorSelectList: [],
            elevatorData: { villageId: '', vProjectId: '' },
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    created() {
        // 获取物业部门
        this.searchList()
        searchVillageSelectList(this.buildParams({})).then(res => {
            this.villageFormItemSelectList = res.info
        })
    },
    methods: {
        searchList() {
            this.searchLoading = true
            this.tabelLoading = true
            queryFaultList(this.formItem).then(res => {
                this.tabelData = res.list
                this.$refs['tblcomponents'].pageIndex = res.pageIndex
                this.$refs['tblcomponents'].pageSize = res.pageSize
                this.totalRecordCnt = res.totalRecordCnt
                this.tabelLoading = false
                this.searchLoading = false
            })
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },
    },


    watch: {

        "formItem.villageId": function(newObj, OldObj) {
            if (newObj) {
                this.elevatorData.villageId = newObj
                getElevatorsStatus(this.elevatorData).then(res => {
                    this.elevatorSelectList = res.info
                })
            } else {
                this.elevatorData.vElevatorCode = ''
                this.elevatorSelectList = []
            }
        }
    }

}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";

::v-deep .ivu-modal-content {
    background-color: #f2f7fb;
}

::v-deep .ivu-modal-header p,
::v-deep .ivu-modal-header-inner {
    font-weight: 700;
    padding-left: 20px;
}

::v-deep .ivu-modal-header {
    border: none;
}

::v-deep .ivu-modal-body {
    padding-top: 0;
}

.ivu-select,
.ivu-date-picker {
    margin-right: 10px;
}

.title {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name {
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
}

.page {
    padding: 0 10px 0;
}

.content {
    padding: 10px;
}
</style>