import http from '@/common/utils/http'

/** 获取维修列表 */
export function queryFaultList(data) {
  return http({
    // url: `RuiJin/searchMaintenanceList`,
    url: `wuye/queryMaintenanceList`,
    method: 'post',
    data
  })
}

/** 维保Pdf */
export function generateMaintenancePdf(params) {
  return http({
    url: `RuiJin/generateMaintenancePdf`,
    method: 'post',
    params
  })
}